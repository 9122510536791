import { AnimatePresence, m } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useClickAway } from 'react-use';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons/faCircleXmark';

export interface DrawerProps {
  isOpen: boolean;
  onClose: AnyFunction;
}

const Drawer: React.FC<DrawerProps> = ({ isOpen, onClose, children }) => {
  const ref = useRef(null);
  useClickAway(ref, onClose);

  useEffect(() => {
    isOpen ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'auto');
  }, [isOpen]);

  return (
    <>
      <m.div
        initial="closed"
        variants={{ open: { translateX: '0%' }, closed: { translateX: '-100%' } }}
        animate={isOpen ? 'open' : 'closed'}
        transition={{ type: 'tween' }}
        ref={ref}
        className="fixed top-0 left-0 h-screen max-w-full transform bg-sumawealth-neutral-0 shadow-md z-[10000]"
      >
        <FontAwesomeIcon
          icon={faCircleXmark}
          onClick={() => onClose()}
          className="fa-lg absolute text-sumawealth-neutral-0 top-0 right-0 cursor-pointer p-6 z-10"
        />
        {children}
        <div />
      </m.div>
      <AnimatePresence>
        {isOpen && (
          <m.div
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="fixed top-0 left-0 z-[110] h-screen w-screen"
          >
            <div className="absolute top-0 left-0 h-screen w-screen bg-sumawealth-neutral-100 bg-opacity-50" />
          </m.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Drawer;
