import { clearAppState } from '@/utils/app';
import { trpc } from '@/utils/trpc';
import useAnalyticsTrack from './useAnalyticsTrack';
import { useRouter } from 'next/router';
import useUser from './useUser';

export default function useLogout() {
  const { user } = useUser();
  const router = useRouter();
  const { queryClient } = trpc.useContext();
  const { mutateAsync, ...mutation } = trpc.useMutation('auth.logout');
  const { track } = useAnalyticsTrack();

  return {
    ...mutation,
    logout: async ({ redirect = true }: { redirect?: boolean }) => {
      track('User Signed Out', {
        username: user?.email,
      });
      await mutateAsync();
      await clearAppState({ queryClient, executeQueryClientCache: true });
      redirect && router.replace('/login');
    },
  };
}
