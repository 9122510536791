import { trpc } from '@/utils/trpc';
import useAuthProfile from './useAuthProfile';

export default function useUserProfile() {
  const { authProfile } = useAuthProfile();

  const { data, ...query } = trpc.useQuery(['user-profile.get'], {
    enabled: authProfile?.isLoggedIn === true,
  });

  return { ...query, userProfile: data };
}
