import { differenceInYears } from 'date-fns';
import omit from 'lodash/omit';

export const createIdentifyPayload = (userProfile: UserProfile) => {
  return {
    userId: userProfile.id,
    traits: getEventTraitsFromUserProfile(userProfile),
  };
};

export const getEventTraitsFromUserProfile = (userProfile: UserProfile) => {
  return {
    age: userProfile.dob && differenceInYears(new Date(), userProfile.dob),
    address: userProfile.address,
    avatar: userProfile.avatar,
    birthday: userProfile.dob,
    email: userProfile.email,
    firstName: userProfile.firstName,
    lastName: userProfile.lastName,
    id: userProfile.id,
    phone: userProfile.phone,
    createdAt: userProfile.createdAt,
    // Facebook Conversions API wants a single letter for gender
    gender: userProfile.gender?.[0],
  };
};

export const convertProductToSegmentSpecProduct = (product: Legacy.Product) => {
  return {
    productId: product.id,
    sku: product.id,
    category: product.categories?.[0],
    name: product.name,
    brand: product.brand?.name,
    retailer: product.retailer?.name,
    price: product.price?.amount,
    currency: product.price?.currency,
    url: product.link,
    imageUrl: product.pictures?.default?.large,
  };
};

export const removeDeprecatedPropertiesFromGoal = (goal: Legacy.GoalWithAggregates) => {
  return omit(goal, [
    'appliedCoupons',
    'completed',
    'fundingSource',
    'isNew',
    'notesForPersonalShopper',
    'occasion',
    'pauseCounts',
    'partnerId',
    'product.colors',
    'product.sizes',
    'product.strategySuggestions',
    'stripe',
    'utm',
  ]);
};
