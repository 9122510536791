import { ContentfulPage, ContentfulBanner, Banner, Card, Subscription } from 'types/contentful';

class Page {
  page: ContentfulPage;

  constructor(page: ContentfulPage) {
    this.page = page;
  }

  get items() {
    return Object.fromEntries(this.page.item.map((x) => [x.fields.id, x.fields]));
  }

  get arrayItems() {
    return this.page.item.map((x) => x.fields);
  }

  public parseBanner(obj: ContentfulBanner): Banner {
    let href = obj.redirectLink;

    if (href === '/paywall') {
      href = `${process.env.NEXT_PUBLIC_LINKR_SUMA_URL}/paywall`;
    }

    return {
      title: obj.title,
      href: href,
      desktopImage: `https:${obj.desktop.fields.file.url}`,
      tabletImage: `https:${obj.tablet.fields.file.url}`,
      mobileImage: `https:${obj.mobile.fields.file.url}`,
      sequence: obj.sequence,
    };
  }

  get carousell(): Card[] {
    return this.page.carousell.map((obj: any) => ({
      title: obj.fields.title,
      href: obj.fields.url,
      description: obj.fields.description,
      image: `https:${obj.fields.image.fields.file.url}`,
    }));
  }

  get subscriptions(): Subscription[] {
    if (!this.page?.subscriptions!) {
      return [];
    }

    return this.page?.subscriptions!.map((obj: any) => ({
      title: obj.fields.title,
      subtitle: obj.fields.subtitle,
      price: obj.fields.price,
      pricePerDay: obj.fields.pricePerDay,
      priceAnnually: obj.fields.priceAnnually,
      frequency: obj.fields.frequency,
      href: `${process.env.NEXT_PUBLIC_LINKR_SUMA_URL}/${obj.fields.url}`,
      description: obj.fields.description,
      image: obj.fields.image ? `https:${obj.fields.image.fields.file.url}` : '',
      button: obj.fields.button,
      id: obj.fields.id,
    }));
  }
}

export default Page;
