import { ElementType } from 'react';
import cn from 'classnames';

export interface TypographyProps {
  variant?: Variant;
  children: React.ReactNode;
  tag?: ElementType;
  htmlFor?: string;
  className?: string;
  size?: Size;
}

export type Variant = keyof typeof defaultVariantConfig;

export type Size = keyof typeof defaultSizeConfig;

export const defaultSizeConfig = {
  '72px': {
    sizeClassName: 'text-[72px] leading-[80px] font-normal',
  },
  '64px': {
    sizeClassName: 'text-[64px] leading-[72px] font-normal',
  },
  '56px': {
    sizeClassName: 'text-[56px] leading-[64px] font-normal',
  },
  '52px': {
    sizeClassName: 'text-[52px] leading-[56px] font-normal',
  },
  '48px': {
    sizeClassName: 'text-[48px] leading-[52px] font-normal',
  },
  '40px': {
    sizeClassName: 'text-[40px] leading-[46px] font-normal',
  },
  '32px': {
    sizeClassName: 'text-[32px] leading-[40px] font-normal',
  },
  '24px': {
    sizeClassName: 'text-[24px] leading-[32px] font-normal',
  },
  '20px': {
    sizeClassName: 'text-[20px] leading-[28px] font-normal',
  },
  '18px': {
    sizeClassName: 'text-[18px] leading-[24px] font-normal',
  },
  '16px': {
    sizeClassName: 'text-[16px] leading-[24px] font-normal',
  },
  '14px': {
    sizeClassName: 'text-[14px] leading-[20px] font-normal',
  },
  '12px': {
    sizeClassName: 'text-[12px] leading-[20px] font-normal',
  },
};

const defaultVariantConfig = {
  heading: {
    defaultTag: 'span',
    defaultClassName: 'font-bebas-neue uppercase text-sumawealth-blue-100',
  },
  body: {
    defaultTag: 'span',
    defaultClassName: 'font-work-sans text-sumawealth-blue-100',
  },
};

export const Typography = ({
  tag,
  variant = 'body',
  size = '16px',
  children,
  className,
  htmlFor,
  ...props
}: TypographyProps) => {
  const { defaultTag, defaultClassName } = defaultVariantConfig[variant];
  const { sizeClassName } = defaultSizeConfig[size];

  const Component = tag || defaultTag;

  return (
    <Component
      className={cn([defaultClassName, className, sizeClassName])}
      htmlFor={htmlFor}
      {...props}
    >
      {children}
    </Component>
  );
};
