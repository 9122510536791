import React from 'react';

export interface DrawerLayoutProps {}

const DrawerLayout: React.FC<DrawerLayoutProps> = ({ children }) => {
  return (
    <div className="w-screen max-w-sm md:max-w-xl relative px-4 py-12 md:p-8 h-full overflow-auto bg-sumawealth-blue-100 text-sumawealth-neutral-0">
      {children}
    </div>
  );
};

export default DrawerLayout;
