import { createIdentifyPayload } from '@/utils/analytics';
import { MouseEventHandler, useCallback } from 'react';
import useUserProfile from './useUserProfile';

export default function useAnalyticsTrack() {
  const { userProfile } = useUserProfile();

  const track = useCallback(
    (event: string, properties?: Object, opts?: SegmentAnalytics.SegmentOpts) => {
      if (userProfile) {
        return window.analytics?.track(event, properties, {
          context: {
            traits: createIdentifyPayload(userProfile!).traits,
            ...opts?.context,
          },
          ...opts,
        });
      } else {
        return window.analytics?.track(event, properties, opts);
      }
    },
    [userProfile]
  );

  const trackButtonClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      track('Button Clicked', {
        id: event.currentTarget.id,
        name: event.currentTarget.dataset.name,
        text: event.currentTarget.innerText,
      });
    },
    [track]
  );

  const trackCheckboxClick: MouseEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      track('Checkbox Clicked', {
        id: event.currentTarget.id,
        name: event.currentTarget.name,
        label: event.currentTarget.parentNode?.textContent || '',
        value: event.currentTarget.checked,
      });
    },
    [track]
  );

  return { track, trackButtonClick, trackCheckboxClick };
}
