import useAuthProfile from './useAuthProfile';
import { useEffect, useRef } from 'react';
import { useLocalStorage } from 'react-use';
import { useRouter } from 'next/router';
import { REEL_USER_LOCAL_STORAGE_KEY } from '@/utils/constants';
// import useRemoveLocalstorage from '@/hooks/suma/useRemoveLocalstorage';

export default function useUser({ redirectTo = '', redirectIfFound = false } = {}) {
  const redirectToRef = useRef(redirectTo); // Use a ref to preserve the value of redirectTo
  const router = useRouter();
  const { authProfile, refetch, status, ...response } = useAuthProfile();

  // Allows non-React code to access info about the current user
  const [, setLocalStorageUser] = useLocalStorage(REEL_USER_LOCAL_STORAGE_KEY);

  useEffect(() => {
    setLocalStorageUser(authProfile);
  }, [setLocalStorageUser, authProfile]);

  useEffect(() => {
    if (!redirectToRef.current || !authProfile) return;

    if (
      status === 'success' &&
      ((redirectToRef.current && !redirectIfFound && !authProfile?.isLoggedIn) ||
        (redirectIfFound && authProfile.isLoggedIn))
    ) {
      if (authProfile.expired) {
        redirectToRef.current = '/expired'; // Update the ref instead of the variable
      }
      router.push(redirectToRef.current); // Use the value from the ref
    }
  }, [authProfile, redirectIfFound, router, status]);

  return { user: authProfile, refreshUser: refetch, ...response };
}
