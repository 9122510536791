import { SUMAWEALTH_LOCALE } from '@/utils/constants';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const getDefaultLanguage = () => 'en-US';

const useLanguage = () => {
  const [currentLocale, setCurrentLocale] = useState<string>(() => {
    const savedLanguage = Cookies.get(SUMAWEALTH_LOCALE);
    return savedLanguage || getDefaultLanguage();
  });

  const languages = [
    { name: 'English', code: 'en_US' },
    { name: 'Spanish', code: 'es' },
  ];

  useEffect(() => {
    const checkLanguage = () => {
      const savedLanguage = Cookies.get(SUMAWEALTH_LOCALE);
      const defaultLanguage = savedLanguage || getDefaultLanguage();
      setCurrentLocale(defaultLanguage);
      Cookies.set(SUMAWEALTH_LOCALE, defaultLanguage);
    };

    checkLanguage();
  }, []);

  const changeLocale = (locale: string, callback?: () => void) => {
    setCurrentLocale(locale);
    Cookies.set(SUMAWEALTH_LOCALE, locale);
    if (callback) {
      callback();
    }
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return { currentLocale, changeLocale, languages };
};

export default useLanguage;
