import { trpc } from '@/utils/trpc';

const FIFTEEN_MINUTES = 1000 * 60 * 15;

export default function useContentfulPageLocaleById(
  id: string,
  locale: string,
  enabled: boolean = true
) {
  const { data, ...query } = trpc.useQuery(['contentful.page-locale-by-id', { id, locale }], {
    enabled: enabled,
    staleTime: FIFTEEN_MINUTES,
  });

  return { ...query, page: data };
}
